import * as React from 'react'

import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

export const Loading = () => (
  <div
    className='container mx-auto h-screen flex flex-col justify-around items-center'
    data-testid='Loading'
  >
    <MerryGoRound size={MerryGoRound.Size.xxl} />
    <div className='container h-1/2 invisible' />
  </div>
)
