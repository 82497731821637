import * as React from 'react'
import cx from 'classnames'
import { RestaurantInfo, Address } from '@local/utils'

export interface AddressFooterProps {
  restaurantInfo?: RestaurantInfo
  testId?: string
}

/**
 * AddressFooter component
 */
export const AddressFooter = ({
  restaurantInfo,
  testId = 'AddressFooter'
}: AddressFooterProps) => {
  if (!restaurantInfo) return null

  return (
    <div className='text-center' data-testid={testId}>
      <p
        className='type-headline-5 font-bold'
        data-testid={`${testId}-rx-name`}
      >
        {getNameWithLocation(restaurantInfo.name, restaurantInfo.locationName)}
      </p>
      {restaurantInfo.address && (
        <div>
          <p className='text-gray-100' data-testid={`${testId}-address`}>
            {getAddressLine(restaurantInfo.address)}
          </p>
          <p className='text-gray-100'>
            <a href={`tel:${restaurantInfo.address.phone}`}>
              {restaurantInfo.address.phone}
            </a>
          </p>
        </div>
      )}
    </div>
  )
}

export function getNameWithLocation(
  restaurantName: string,
  locationName?: string
) {
  return locationName ? restaurantName + ' - ' + locationName : restaurantName
}

export function getAddressLine(address: Address) {
  const fullAddress = address.address2
    ? `${address.address1} ${address.address2}`
    : address.address1
  return `${fullAddress}, ${address.city}, ${address.state} ${address.zip}`
}
