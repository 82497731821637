export const isValidGUID = (guid: string) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    guid
  )

export const NotFoundErrorTitle = 'Whoops!'
export const NotFoundErrorSubtitle1 =
  'Looks like you might have tried to check in for an old order.'
export const NotFoundErrorSubtitle2 =
  'If you think you got this message in error, please contact the restaurant where you are expecting to pick up your order.'

export const GenericErrorTitle = 'Whoops! 404.'
export const GenericErrorSubtitle =
  'Sorry, the page you were looking for at this URL was not found.'

// Invalid input returns original input
export const replaceSpecialCharacters = function (str: string) {
  str = str.replace(/([‘’`])/g, "'")
  str = str.replace(/([”“])/g, '"')

  return str
}
