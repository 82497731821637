import * as React from 'react'
import {
  GenericErrorTitle,
  GenericErrorSubtitle,
  NotFoundErrorSubtitle1,
  NotFoundErrorSubtitle2,
  NotFoundErrorTitle,
  replaceSpecialCharacters
} from '@local/utils'
import { AddressFooter } from '@local/address-footer'
import { Loading } from './Loading'
import { useQuery, useMutation } from '@apollo/client'
// @ts-ignore
import { CURBSIDE_ARRIVAL_QUERY } from '../../../api/graphql/queries/curbsideArrival.graphql'
// @ts-ignore
import { COMPLETE_CURBSIDE_ARRIVAL } from '../../../api/graphql/mutations/completeOrder.graphql'
import { useParams } from 'react-router-dom'
import { Textarea } from '@toasttab/buffet-pui-text-area'
import { CheckInButton } from '@local/check-in-button'
import { useEffect, useState } from 'react'

/**
 * Layout component
 */

export const Layout = () => {
  const [numMutationAttempts, setNumMutationAttempts] = useState(0)
  const [notes, setNotes] = useState('')
  const { guid } = useParams()

  useEffect(() => {
    document.title = 'Toast Curbside Arrival Check In'
  })

  const dataByTypename = (data: any): any =>
    data && data.__typename ? { [data.__typename]: data } : {}

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData
  } = useQuery(CURBSIDE_ARRIVAL_QUERY, {
    variables: { guid }
  })

  useEffect(() => {
    setNotes(queryData?.curbsideArrival?.notes ?? '')
  }, [queryData])

  const {
    CurbsideArrival: validQueryData,
    NotFoundError: queryThrewNotFoundError,
    GenericError: queryThrewGenericError
  } = dataByTypename(queryData?.curbsideArrival)

  const [
    completeCheckInMutation,
    { data: mutationData, loading: mutationLoading }
  ] = useMutation(COMPLETE_CURBSIDE_ARRIVAL, {
    onCompleted: () => setNumMutationAttempts(numMutationAttempts + 1),
    onError: () => setNumMutationAttempts(numMutationAttempts + 1)
  })

  const { CheckInResponse: validMutationData } = dataByTypename(
    mutationData?.completeCurbsideArrival
  )

  if (queryLoading) {
    return <Loading />
  }

  let restaurantInfo
  let checkInState
  let title
  let subtitle
  let showNotes
  if (validQueryData) {
    restaurantInfo = validQueryData.restaurantInfo
    checkInState = validQueryData.checkedInStatus

    // Mutation checkedInState supersedes the query checkedInState
    if (validMutationData) {
      checkInState = validMutationData.checkedInStatus
    }

    title = validQueryData.textsToDisplay[checkInState].title
    subtitle = validQueryData.textsToDisplay[checkInState].subtitle

    showNotes = validQueryData.features.showNotes
  }

  const completeCheckIn = () => {
    completeCheckInMutation({
      variables: { guid, notes: replaceSpecialCharacters(notes) }
    })
  }

  const updateNotes = (e: any) => setNotes(e.target.value)

  let subtitle2
  if (queryThrewNotFoundError) {
    title = NotFoundErrorTitle
    subtitle = NotFoundErrorSubtitle1
    subtitle2 = NotFoundErrorSubtitle2
  } else if (queryThrewGenericError || queryError) {
    title = GenericErrorTitle
    subtitle = GenericErrorSubtitle
  }

  return (
    <div className='h-1/2 flex flex-col justify-around items-center space-y-3 font-sans w-full mt-4'>
      <header>
        <h1 className='mb-0 type-headline-1 text-center font-bold text-primary-75'>
          {title}
        </h1>
      </header>
      <main>
        <p id='subtitle' className='p-3 mx-4 mb-1 text-center text-secondary'>
          {subtitle}
        </p>
        {subtitle2 && (
          <p
            id='subtitle2'
            className='p-3 mx-4 mb-1 text-center text-secondary'
          >
            {subtitle2}
          </p>
        )}
        {checkInState === 'NOT_CHECKED_IN' && (
          <>
            {showNotes && (
              <div className='mx-4 items-center'>
                <Textarea
                  data-testid='curbside-arrival-notes'
                  name='curbside-arrival-notes'
                  value={notes}
                  onChange={updateNotes}
                  maxLength={100}
                  placeholder='Optional: tell us anything special about your vehicle, or where you parked.'
                  className='resize-none'
                />
              </div>
            )}
            <div className='flex flex-col'>
              <CheckInButton
                loading={mutationLoading}
                completeCheckIn={completeCheckIn}
                numAttempts={numMutationAttempts}
              />
            </div>
          </>
        )}
      </main>
      <footer>
        <AddressFooter restaurantInfo={restaurantInfo} />
      </footer>
    </div>
  )
}
