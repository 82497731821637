import * as React from 'react'
import { Layout } from '@local/layout'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ErrorBoundary from '../../packages/error/src/Error/ErrorBoundary'
import GraphQLClient from '../../packages/api/graphql/graphql_client'
import { ApolloProvider } from '@apollo/client'

export function App() {
  const router = createBrowserRouter([
    {
      path: '/curbside/:guid',
      element: <Layout />
    }
  ])
  return (
    <ErrorBoundary>
      <ApolloProvider client={GraphQLClient}>
        <RouterProvider router={router} />
      </ApolloProvider>
    </ErrorBoundary>
  )
}
