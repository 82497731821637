import * as React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'

export interface CheckInButtonProps {
  loading: boolean
  numAttempts: number
  completeCheckIn: () => void
}

/**
 * CheckInButton component
 */

export const CheckInButton = ({
  loading,
  numAttempts,
  completeCheckIn
}: CheckInButtonProps) => {
  let retryText
  switch (numAttempts) {
    case 0:
      break
    case 1:
      retryText = (
        <p className='type-caption text-error'>
          Something went wrong. Please try to check in again.
        </p>
      )
      break
    default:
      retryText = (
        <>
          <p className='type-caption text-error'>
            Something went wrong. Please try to check in again.
          </p>
          <p className='type-caption text-error'>
            If the issue persists, please call the restaurant to check in.
          </p>
        </>
      )
  }

  return (
    <>
      <Button
        className='text-center m-4'
        data-testid='CheckInButton'
        disabled={loading}
        onClick={completeCheckIn}
      >
        {loading ? 'One moment please...' : "I'm here"}
      </Button>
      {retryText}
    </>
  )
}
