import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
// @ts-ignore
import envFromHostname from '@toasttab/env-from-hostname'

const env = envFromHostname(window.location.hostname)
let prefix
if (env === 'dev') {
  prefix = 'ws-dev.eng.toastteam.com'
} else if (env === 'prod') {
  prefix = 'ws-api.toasttab.com'
} else {
  prefix = 'ws-preprod-api.eng.toasttab.com'
}

let uri = `https://${prefix}/curbside-arrival-bff/v1/graphql`
if (env === 'dev') {
  // This is just a hack until the gateway PR is merged
  uri = 'http://localhost:15545/v1/graphql'
}
const httpLink = new HttpLink({ uri, credentials: 'same-origin' })

const GraphQLClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

export default GraphQLClient
