// @ts-nocheck
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

const defaultErrorContent = () => (
  <span>
    <span role='img' aria-label='warning'>
      ⚠️
    </span>{' '}
    Something went wrong
  </span>
)

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error)
  }

  render() {
    const { error, errorInfo } = this.state
    const { errorContent = defaultErrorContent } = this.props

    if (error) {
      return errorContent(error, errorInfo)
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  errorContent: PropTypes.func
}

export default ErrorBoundary
